<template>
  <div id="popup" class="popup" :class="hide_check ? 'hide' : ''">
    <slot></slot>
    <div class="text">
      <input
        type="checkbox"
        :checked="hide_check"
        @input="checkboxInput($event.target.checked)"
      />
      <span>하루 동안 보지 않겠습니다.</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  data() {
    return {
      hide_check: true
    }
  },
  props: ['cookie', 'use'],
  mounted() {
    let popup_cookie = this.$cookies.get(this.cookie)
    if (this.use !== 'Y') {
      this.hide_check = true
      return false
    }
    if (popup_cookie && popup_cookie === '1') {
      this.hide_check = true
    } else {
      this.hide_check = false
    }
  },
  methods: {
    checkboxInput(value) {
      this.hide_check = value
      if (value === true) {
        this.$cookies.set(this.cookie, '1', '1d')
      }
    }
  }
}
</script>

<style scoped>
#popup {
  position: absolute;
  left: calc(50% + 50px);
  top: 140px;
  z-index: 10;
}
.hide {
  display: none;
}
.text {
  display: flex;
  justify-items: center;
  align-items: center;
  background: white;
  padding: 6px;
}
.text input {
  margin-right: 6px;
}
</style>
