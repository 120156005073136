<template>
  <div id="home">
    <popup cookie="home-popup" use="N">
      <img
        src="https://moconsweb.blob.core.windows.net/upload/images/NOTICE_2023_2.png"
        alt=""
      />
    </popup>
  </div>
</template>

<script>
import popup from '@/components/Popup'
export default {
  name: 'HomeLayout',
  components: {
    popup: popup
  }
}
</script>

<style scoped>
#home {
  width: 100%;
  min-width: 1000px;
  height: 486px;
  background-image: url('/images/home-image.jpg');
  background-position: center center;
}
</style>
